import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/Layout";
import { HeroSection } from "@shared";
import * as C from "@components/ServicesPage";
import { TOurServices } from "@types";

const ServicesPage = ({ data }: TOurServices) => {
  const { seo, hero } = data.strapiOurServicesPage;
  const bgHero = { bgMobile: hero.bgMobile, bgDesktop: hero.bgDesktop };
  return (
    <Layout seo={seo}>
      <HeroSection
        bgHero={bgHero}
        title={hero.title}
        description={hero.description}
      />
      <C.Content />
    </Layout>
  );
};

export default ServicesPage;

export const query = graphql`
  {
    strapiOurServicesPage {
      hero {
        title
        description
        bgMobile {
          localFile {
            publicUrl
          }
        }
        bgDesktop {
          localFile {
            publicUrl
          }
        }
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicUrl
          }
        }
      }
    }
  }
`;
