import styled, { css } from "styled-components";
import * as SH from "@shared";
import PatternBlue from "./assets/pattern-blue.svg";
import PatternYellow from "./assets/pattern-yellow.svg";
import PatternWhite from "./assets/pattern-white.svg";
import * as theme from "@theme";

export const WrapperBox = styled(SH.Wrapper.Box)`
  position: relative;
  z-index: 1;
  max-height: 645px;
  height: 600px;
  &:first-of-type,
  &:nth-of-type(2) {
    &:after {
      content: "";
      width: 319.5px;
      height: 125px;
      position: absolute;
      display: none;
      background-repeat: no-repeat;
      z-index: -1;
      top: 50%;
      left: 60%;
      @media ${theme.breakpoints.desktop} {
        display: block;
        background-image: url(${PatternBlue});
        top: 0;
        left: auto;
        right: -200px;
      }
    }
  }
  &:nth-of-type(2) {
    &:after {
      display: none;
      top: auto;
      left: auto;
      bottom: 80px;
      right: 0;
      @media ${theme.breakpoints.desktop} {
        display: block;
        bottom: 50px;
        right: 50px;
      }
    }
  }
  @media ${theme.breakpoints.desktop} {
    max-height: 545px;
    height: 545px;
  }
`;

export const Image = styled(SH.Image)`
  left: -1px;
`;
