import { useStaticQuery, graphql } from "gatsby";

const useFetchService = () => {
  const data: TData = useStaticQuery(graphql`
    {
      allStrapiOurServicesPage {
        edges {
          node {
            content {
              title
              id
              description
              imgDesktop {
                alternativeText
                localFile {
                  publicUrl
                }
              }
              imgMobile {
                alternativeText
                localFile {
                  publicUrl
                }
              }
            }
          }
        }
      }
    }
  `);

  return { data };
};

export default useFetchService;

type TData = {
  allStrapiOurServicesPage: {
    edges: {
      node: {
        content: {
          title: string;
          id: number;
          description: string;
          imgDesktop: {
            alternativeText: string;
            localFile: {
              publicUrl: string;
            };
          };
          imgMobile: {
            alternativeText: string;
            localFile: {
              publicUrl: string;
            };
          };
        }[];
      };
    }[];
  };
};
