//@ts-ignore

import React from "react";
import * as SH from "@shared";
import * as S from "./Content.styles";
import { Row, Container, Col } from "react-bootstrap";
import useFetchService from "../ServicesPage.hooks";
import ReactHtmlParser from "react-html-parser";

export const Content = () => {
  const { data } = useFetchService();
  return (
    <SH.Wrapper.Primary>
      <Container>
        <Row className="justify-content-center">
          <Col lg="12" xl="10">
            {!!data.allStrapiOurServicesPage.edges[0].node.content &&
              data.allStrapiOurServicesPage.edges[0].node.content.map(
                ({ title, description, id, imgDesktop, imgMobile }) => (
                  <S.WrapperBox key={id}>
                    <Row className="h-100 justify-content-between row-box">
                      <Col lg="6" xl="5">
                        <SH.Text.Header2>{renderAnd(title)}</SH.Text.Header2>
                        <SH.Text.MarkdownTextBody className="pt-3">
                          {ReactHtmlParser(description)}
                        </SH.Text.MarkdownTextBody>
                      </Col>
                      <Col
                        className="d-flex justify-content-center align-self-lg-center"
                        lg="6"
                      >
                        {/* temp resolve before gatsby image */}
                        {imgDesktop && (
                          <S.Image
                            className="d-none d-lg-block"
                            heightImg={heightImg}
                            src={imgDesktop.localFile.publicUrl}
                            alt={imgDesktop.alternativeText}
                          />
                        )}
                        {imgMobile && (
                          <SH.Img.Plugin
                            className="d-lg-none"
                            heightImg={heightImg}
                            src={imgMobile.localFile.publicUrl}
                            alt={imgMobile.alternativeText}
                          />
                        )}
                        {/* temp resolve before gatsby image */}
                      </Col>
                    </Row>
                  </S.WrapperBox>
                )
              )}
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};

const renderAnd = (title: string) => {
  return title.split(" &").map((line: string, index) => {
    return (
      <React.Fragment key={index}>
        {line}
        {index === 0 && (
          <>
            <br />
            <SH.Text.Span colorComponent="lightblue"> &</SH.Text.Span>
          </>
        )}
      </React.Fragment>
    );
  });
};

const heightImg = {
  desktop: 314.99,
  mobile: 295,
};
